import {
  faRepeat,
  faSignOut,
  faSpinner,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
} from "@mui/material";
import { Core } from "cmk-core";
import { DiscordAuth } from "ctrls/auth";
import { auth, db } from "ctrls/firebase";
import { User, signOut } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { memo, useEffect, useState } from "react";

class Balance {
  static watch(user: User, callback: (value: number) => void) {
    return onSnapshot(doc(db, "users", user.uid), (snapshot) => {
      const data = snapshot.data();
      callback(Number(data?.balance ?? 0));
    });
  }

  static async reload(user: User) {
    return new Promise<void>(async (resolve, reject) => {
      fetch(DiscordAuth.endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authentication: `Bearer ${await user.getIdToken()}`,
        },
        body: JSON.stringify({
          action: "balance_reload",
        }),
      })
        .then((res) => res.json())
        .then(() => resolve());
    });
  }
}

export const BalanceBtn = memo(() => {
  const {
    state: { loading, user },
  } = Core.useCore();
  const [state, setState] = useState<{ loading: boolean; amount: number }>({
    loading: true,
    amount: 0,
  });

  useEffect(() => {
    if (loading === false && user) {
      return Balance.watch(user, (amount) => {
        setState({ loading: false, amount });
      });
    }
  }, [loading, user]);

  const handleReload = () => {
    if (user) {
      setState({ ...state, loading: true });
      Balance.reload(user).then(() => {
        setState({ ...state, loading: false });
      });
    }
  };

  return state.loading || user ? (
    <>
      <ListItemButton disabled={state.loading} onClick={handleReload}>
        <ListItemAvatar>
          {state.loading ? (
            <FontAwesomeIcon icon={faSpinner} pulse size="2x" />
          ) : (
            <Avatar src={user?.photoURL ?? undefined} />
          )}
        </ListItemAvatar>
        <ListItemText
          primary={state.loading ? <Skeleton width="60%" /> : user?.displayName}
          primaryTypographyProps={{ variant: "h6", textTransform: "uppercase" }}
          secondary={
            state.loading ? (
              <Skeleton width="30%" />
            ) : (
              `Balance ${state.amount} THB`
            )
          }
          secondaryTypographyProps={{
            variant: "caption",
          }}
        />
        <ListItemSecondaryAction>
          <IconButton size="small">
            <FontAwesomeIcon icon={faRepeat} size="xs" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItemButton>
      <ListItemButton
        sx={{ color: "error.main" }}
        onClick={() => signOut(auth)}
      >
        <ListItemIcon>
          <FontAwesomeIcon icon={faSignOut} />
        </ListItemIcon>
        <ListItemText primary="Sign Out" />
      </ListItemButton>
    </>
  ) : null;
});
