import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AppBar,
  Avatar,
  Box,
  BoxProps,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuProps,
  Stack,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import { Core } from "cmk-core";
import {
  faSignIn,
  faSignOut,
  faSpinner,
} from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";
import { User, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export namespace MainContainer {
  export type RootProps = BoxProps;
  export const Root = styled((props: RootProps) => {
    const { state, appbar } = Core.useCore();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const nav = useNavigate();

    const handleUserButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (state.user) {
        setAnchorEl(e.currentTarget);
      } else {
        nav("/signin");
      }
    };
    const handleSignOut = () => {
      if (state.auth) {
        signOut(state.auth);
      }
    };

    return (
      <Box {...props}>
        <AB>
          <Toolbar>
            {appbar?.start}
            <Box flexGrow={1} />
            <Stack direction="row" spacing={1} alignItems="center">
              {appbar?.end}
              <IconButton
                edge="end"
                size="large"
                onClick={handleUserButtonClick}
              >
                {state.loading ? (
                  <FontAwesomeIcon icon={faSpinner} pulse />
                ) : state.user ? (
                  <Avatar src={state.user.photoURL ?? undefined} />
                ) : (
                  <FontAwesomeIcon icon={faSignIn} />
                )}
              </IconButton>
            </Stack>
          </Toolbar>
        </AB>
        {props.children}
        <UserMenu
          user={state.user}
          open={Boolean(state.user && anchorEl)}
          anchorEl={anchorEl}
          onSignOut={handleSignOut}
          onClose={() => setAnchorEl(null)}
        />
      </Box>
    );
  })({});

  const AB = styled(AppBar)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxSizing: "border-box",
  }));
  AB.defaultProps = {
    position: "sticky",
    elevation: 0,
    color: "default",
  };

  export type UserMenuProps = MenuProps & {
    user?: User | null;
    onSignOut?: () => void;
  };
  const UserMenu = styled(
    React.forwardRef<HTMLDivElement, UserMenuProps>(
      ({ user, onSignOut, ...props }, ref) => {
        return (
          <Menu
            ref={ref}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            MenuListProps={{ disablePadding: true }}
            {...props}
          >
            <Stack alignItems="center" spacing={2} sx={{ pt: 2 }}>
              <Avatar
                src={user?.photoURL ?? undefined}
                sx={{ width: 96, height: 96 }}
              />
              <Typography>{user?.displayName}</Typography>
              <List sx={{ width: "100%" }}>
                <ListItemButton
                  sx={{ color: "error.main" }}
                  onClick={onSignOut}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faSignOut} />
                  </ListItemIcon>
                  <ListItemText primary="Sign Out" />
                </ListItemButton>
              </List>
            </Stack>
          </Menu>
        );
      }
    )
  )(({ theme }) => ({
    ".MuiPaper-root": {
      ...theme.mixins.sidebar,
    },
  }));
}
