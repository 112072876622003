export class DiscordAuth {
  static endpoint: string =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3151"
      : "https://baryr59y30.execute-api.ap-southeast-1.amazonaws.com/test/cmk-api";

  static signIn(hash: string) {
    return new Promise<string>((resolve, reject) => {
      const hashes = Object.fromEntries(
        hash
          .slice(1)
          .split("&")
          .map((v) => v.split("="))
      );
      if (hashes.access_token) {
        fetch(this.endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token: hashes.access_token }),
        })
          .then((res) => res.json())
          .then((res) => {
            resolve(res.token);
          });
      }
    });
  }
}
