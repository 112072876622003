import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { FirebaseApp } from "firebase/app";
import { Auth, User, getAuth } from "firebase/auth";
import { Firestore, getFirestore } from "firebase/firestore";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react";

declare module "@mui/material/styles/createMixins" {
  interface Mixins {
    sidebar: CSSProperties;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    discord: Palette["primary"];
  }
  interface PaletteOptions {
    discord: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    discord: true;
  }
}

export * from "./action.icon";
export * from "./main.container";

export namespace Core {
  export type StateAction =
    | { type: "user"; user: User | null }
    | { type: "app"; app: FirebaseApp };
  export class State {
    loading: boolean = true;
    user: User | null = null;
    app: FirebaseApp | null = null;
    auth: Auth | null = null;
    db: Firestore | null = null;

    constructor(data?: State) {
      Object.assign(this, data);
    }

    static reducer(s: State, a: StateAction): State {
      switch (a.type) {
        case "user":
          return new State({ ...s, loading: false, user: a.user });
        case "app":
          return new State({
            ...s,
            app: a.app,
            auth: getAuth(a.app),
            db: getFirestore(a.app),
          });
        default:
          return s;
      }
    }
  }

  // ANCHOR - AppBarConfig
  type AppBarProps = {
    start?: ReactNode;
    end?: ReactNode;
  };

  export const Context = createContext<{
    state: State;
    dispatch: React.Dispatch<StateAction>;
    appbar?: AppBarProps;
  }>({
    state: new State(),
    dispatch: () => {},
  });

  export const useCore = () => {
    const context = useContext(Context);
    return context;
  };

  export type ProviderProps = {
    children?: ReactNode;
    app: FirebaseApp;
    appbar?: AppBarProps;
  };
  export const Provider = ({ appbar, ...props }: ProviderProps) => {
    const [state, dispatch] = useReducer(State.reducer, new State());

    useEffect(() => {
      dispatch({ type: "app", app: props.app });
      const auth = getAuth(props.app);
      const unsubscribe = auth.onAuthStateChanged((user) =>
        dispatch({ type: "user", user })
      );
      return () => unsubscribe();
    }, [props.app]);

    return (
      <Context.Provider value={{ state, dispatch, appbar }}>
        <ThemeProvider
          theme={createTheme({
            palette: {
              primary: {
                main: "#FFCB05",
              },
              discord: {
                main: "#7289da",
                contrastText: "#fff",
              },
              mode: "dark",
            },
            mixins: {
              sidebar: {
                width: 272,
                maxWidth: "100%",
              },
            },
            typography: {
              fontFamily: "Sriracha",
            },
            components: {
              MuiIconButton: {
                styleOverrides: {
                  sizeLarge: {
                    width: 56,
                    height: 56,
                  },
                },
              },
              MuiListItemIcon: {
                styleOverrides: {
                  root: {
                    minWidth: 36,
                    color: "inherit",
                  },
                },
              },
            },
          })}
        >
          <CssBaseline />
          {props.children}
        </ThemeProvider>
      </Context.Provider>
    );
  };
}
