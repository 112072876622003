import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAC3MAPvQSN8GJxhT-KqVSRFpKyCbn2SUM",
  authDomain: "cmk-api.firebaseapp.com",
  databaseURL: "https://cmk-api.firebaseio.com",
  projectId: "cmk-api",
  storageBucket: "cmk-api.appspot.com",
  messagingSenderId: "429609444723",
  appId: "1:429609444723:web:c9e5e07c5912850c"
};

export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);
connectFunctionsEmulator(functions, "localhost", 5001);