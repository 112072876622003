import { Box, Typography, alpha, styled } from "@mui/material";
import pikachu from "./pikachu-running.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";

export const Loading = styled((props) => {
  return (
    <Box {...props}>
      <img src={pikachu} alt="loading" />
      <Typography variant="h6">
        <FontAwesomeIcon icon={faSpinner} pulse />
        &nbsp; L O A D I N G
      </Typography>
    </Box>
  );
})(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 9999,
  backgroundColor: alpha(theme.palette.background.paper, 0.75),
  backdropFilter: "blur(4px)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(2),
  img: {
    width: 256,
    maxWidth: "50%",
    imageRendering: "pixelated",
  },
}));
