import { Suspense, lazy } from "react";
import { BrowserRouter } from "react-router-dom";
import { Core } from "cmk-core";
import { app } from "ctrls/firebase";
import { BalanceBtn } from "components/balance.btn";
import { Loading } from "components/loading";

const PageLanding = lazy(() => import("./pages/landing"));

const Routing = () => {
  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <PageLanding />
      </BrowserRouter>
    </Suspense>
  );
};

function App() {
  return (
    <Core.Provider
      app={app}
      appbar={{
        end: <BalanceBtn />,
      }}
    >
      <Routing />
    </Core.Provider>
  );
}

export default App;
