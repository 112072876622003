import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { IconButton, IconButtonProps, styled } from "@mui/material";

export type ActionIconProps = IconButtonProps &
  Pick<FontAwesomeIconProps, "icon">;
export const ActionIcon = styled(({ icon, ...props }: ActionIconProps) => (
  <IconButton size="small" {...props}>
    <FontAwesomeIcon icon={icon} size="xs" />
  </IconButton>
))<ActionIconProps>({});
